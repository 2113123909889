import { mapState } from 'vuex'
import filters from '@/libs/filters'
import { DAY_MONTH_YEAR } from '@/libs/filterDate'
import chatButton from '@/libs/chatButton'
import provider from '@/provider/provider'
import { kompackAxiosIns } from '@/libs/axios'
import { RAJAONGKIR_SHIPPING, OUTBOUND_NON_PICKUP } from '@/provider/url'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    vSelect,
  },
  filters: { rupiah: filters.rupiah },
  data() {
    return {
      listEkspedisi: [],
      ekspedisiLainnya: '',
      ekspedisi: {},
      noResi: '',
      ongkosKirim: '',

      imageFile: [],
      fileName: 'Lampirkan File',

      fields: [
        {
          key: 'product_name', label: 'Nama Produk', thClass: 'text-capitalize text-left text-dark', tdClass: 'text-left',
        },
        {
          key: 'variant', label: 'Variasi', thClass: 'text-capitalize text-left text-dark', tdClass: 'text-left',
        },
        {
          key: 'stock', label: 'Stok', thClass: 'text-capitalize text-left text-dark', tdClass: 'text-left',
        },
        {
          key: 'total_submission', label: 'Jumlah Pengajuan', thClass: 'text-capitalize text-left text-dark', tdClass: 'text-left',
        },
      ],
      DAY_MONTH_YEAR,
      chatButton,
    }
  },
  async mounted() {
    await this.$store.commit('outboundNonPacking/UPDATE_ID_DETAIL', this.$route.params.id)
    await this.fetchDetail()
    if (this.detailOutbound.shipping_type === 2) {
      await this.fetchShipping()
    }
  },
  computed: {
    ...mapState('outboundNonPacking', ['detailOutbound']),
  },
  methods: {
    async fetchDetail() {
      await this.$store.dispatch('outboundNonPacking/getDetailOutbound')
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text:
              'Unable to load the table data. Please try again later or contact support.',
              variant: 'danger',
            },
          }, { timeout: 2000 })
        })
    },
    async fetchShipping() {
      await provider.list(RAJAONGKIR_SHIPPING)
        .then(response => {
          this.listEkspedisi.push(...response.data)
          this.listEkspedisi.push(...[{
            courier_code: '',
            name: 'Lainnya',
            id: 1,
          }])
        })
    },
    async approve() {
      let body = {}
      if (this.detailOutbound.shipping_type === 2) {
        body = {
          shipping: this.ekspedisiLainnya,
          shipping_waybill: this.noResi,
          shipping_cost: parseInt(this.ongkosKirim, 10),
        }
      } else {
        body = {
          shipping: null,
          shipping_waybill: null,
          shipping_cost: null,
        }
      }
      await provider.update(`${OUTBOUND_NON_PICKUP}/${this.detailOutbound.outbound_id}/approve`, body)
        .then(() => {
          if (this.detailOutbound.shipping_type === 2) {
            this.uploadProof()
          } else {
            this.successProcess()
          }
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: 'Gagal memproses pengeluaran barang. Silahkan coba lagi atau hubungi support kami.',
              variant: 'danger',
            },
          }, { timeout: 2000 })
        })
    },
    async uploadProof() {
      const formData = new FormData()
      formData.append('proof_file', this.imageFile[0])
      kompackAxiosIns
        .post(`${OUTBOUND_NON_PICKUP}/${this.detailOutbound.outbound_id}/upload-proof`, formData, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
        .then(() => {
          this.successProcess()
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: 'Gagal memproses pengeluaran barang. Silahkan coba lagi atau hubungi support kami.',
              variant: 'danger',
            },
          }, { timeout: 2000 })
        })
    },
    refuse() {
      this.$store.dispatch('outboundNonPacking/refuse')
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              text: 'Successfully Reject',
              variant: 'success',
              icon: 'CheckIcon',
            },
          }, { timeout: 2000 })

          this.$router.push('/outbound/pengeluaran-barang')
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text:
                'Unable to load the table data. Please try again later or contact support.',
              variant: 'danger',
            },
          }, { timeout: 2000 })
        })
    },
    sendPackage() {
      this.$swal({
        title: 'Pengeluaran Barang',
        text: 'Kamu yakin untuk memproses pengeluaran barang ini? harap hubungi partner untuk meminta penggantian ongkos kirim',
        icon: 'warning',
        iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
        showCancelButton: true,
        cancelButtonText: 'Batal',
        confirmButtonText: 'Proses',
        customClass: {
          icon: 'border-0 w-50 my-5',
          cancelButton: 'btn btn-outline-primary mr-1 px-4',
          confirmButton: 'btn btn-primary px-4',
        },
        reverseButtons: true,
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.approve()
        }
      })
    },
    successProcess() {
      this.$swal({
        title: 'Pengeluaran Barang Diproses',
        text: 'Kamu yakin untuk memproses pengeluaran barang ini? harap hubungi partner untuk meminta penggantian ongkos kirim',
        icon: 'success',
        width: '70%',
        iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-success.png">',
        showCancelButton: true,
        cancelButtonText: 'Hubungi Partner',
        confirmButtonText: 'Selesai',
        customClass: {
          icon: 'border-0 w-50 my-5',
          cancelButton: 'btn btn-outline-primary mr-1 px-4',
          confirmButton: 'btn btn-primary px-4',
        },
        reverseButtons: true,
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.fetchDetail()
        } else {
          this.chatButton(this.detailOutbound.partner_phone)
          this.fetchDetail()
        }
      })
    },
    changeEkspedisi() {
      if (this.ekspedisi.name !== 'Lainnya') {
        this.ekspedisiLainnya = this.ekspedisi.name
      } else {
        this.ekspedisiLainnya = ''
      }
    },
    formatterOngkir(value) {
      return value.replace(/\D/g, '')
    },
    handleProsesButton() {
      if (this.detailOutbound.shipping_type === 2) {
        this.$bvModal.show('modal-data-pengiriman')
      } else {
        this.sendPackage()
      }
    },
    onChangeFile(event) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0]
        const allowedTypes = ['image/jpg', 'image/jpeg', 'image/png']

        if (!allowedTypes.includes(file.type)) {
          this.showErrorToast('File yang diupload harus berupa gambar (JPG, JPEG, atau PNG)')
          return
        }

        const reader = new FileReader()
        reader.onloadend = () => {
          const arr = new Uint8Array(reader.result)
          const header = arr.subarray(0, 4)
          const headerString = String.fromCharCode.apply(null, header)

          if (headerString === 'RIFF' || headerString === '<!DO') {
            this.showErrorToast('File yang diupload harus berupa gambar (JPG, JPEG, atau PNG)')
            return
          }

          const img = new Image()
          img.onload = () => {
            if (img.width < 300 || img.height < 300) {
              this.showErrorToast('File yang diupload harus memiliki dimensi minimal 300 x 300 piksel')
              return
            }

            this.fileName = file.name
            // Additional processing or storing the file, if needed
          }
          img.src = URL.createObjectURL(file)
        }
        reader.readAsArrayBuffer(file)
      } else {
        this.fileName = 'Lampirkan File'
      }
    },

    showErrorToast(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failure',
          icon: 'AlertCircleIcon',
          text: message,
          variant: 'danger',
        },
      }, { timeout: 2000 })
      this.fileName = 'Lampirkan File'
      this.imageFile = []
    },
  },
}
